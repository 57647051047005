import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60')
];

export const server_loads = [0,2,3,4];

export const dictionary = {
		"/(app)/(dashboard)": [6,[2]],
		"/.well-known/[...catchAll]": [60],
		"/(app)/(dashboard)/adyen": [8,[2]],
		"/(app)/(dashboard)/campaign-codes": [~9,[2]],
		"/(app)/(dashboard)/campaign-codes/create": [11,[2]],
		"/(app)/(dashboard)/campaign-codes/[code]": [~10,[2]],
		"/(app)/(dashboard)/faq": [~12,[2]],
		"/(app)/(dashboard)/faq/create-section": [14,[2]],
		"/(app)/(dashboard)/faq/section/[id]": [~15,[2]],
		"/(app)/(dashboard)/faq/section/[id]/create-question": [16,[2]],
		"/(app)/(dashboard)/faq/[id]": [~13,[2]],
		"/(app)/(dashboard)/integration-cost": [17,[2]],
		"/(app)/(dashboard)/legacy/[...catchAll]": [~18,[2]],
		"/(auth)/login": [58,[5]],
		"/(app)/(dashboard)/payouts": [~19,[2]],
		"/(app)/(dashboard)/payouts/[id]": [~20,[2]],
		"/(app)/(dashboard)/settings": [21,[2]],
		"/(auth)/signout": [59,[5]],
		"/(app)/(dashboard)/strings": [~22,[2]],
		"/(app)/(dashboard)/strings/create": [24,[2]],
		"/(app)/(dashboard)/strings/[id]": [~23,[2]],
		"/(app)/(dashboard)/studios": [25,[2]],
		"/(app)/(dashboard)/studios/create-studio": [37,[2]],
		"/(app)/(dashboard)/studios/[id]": [26,[2,3]],
		"/(app)/(dashboard)/studios/[id]/about": [27,[2,3]],
		"/(app)/(dashboard)/studios/[id]/alert": [28,[2,3]],
		"/(app)/(dashboard)/studios/[id]/alert/[alertId]": [~29,[2,3]],
		"/(app)/(dashboard)/studios/[id]/classes": [~30,[2,3]],
		"/(app)/(dashboard)/studios/[id]/classes/schedule": [32,[2,3]],
		"/(app)/(dashboard)/studios/[id]/classes/[class]": [~31,[2,3]],
		"/(app)/(dashboard)/studios/[id]/edit": [~33,[2,3]],
		"/(app)/(dashboard)/studios/[id]/legacy/[...catchAll]": [~34,[2,3]],
		"/(app)/(dashboard)/studios/[id]/mapping": [~35,[2,3]],
		"/(app)/(dashboard)/studios/[id]/price": [~36,[2,3]],
		"/(app)/(dashboard)/swagger": [~38,[2]],
		"/(app)/(dashboard)/trial": [39,[2]],
		"/(app)/(dashboard)/users": [40,[2]],
		"/(app)/(dashboard)/users/[id]": [41,[2,4]],
		"/(app)/(dashboard)/users/[id]/about": [42,[2,4]],
		"/(app)/(dashboard)/users/[id]/agreements": [43,[2,4]],
		"/(app)/(dashboard)/users/[id]/block-user": [44,[2,4]],
		"/(app)/(dashboard)/users/[id]/booking-limits": [45,[2,4]],
		"/(app)/(dashboard)/users/[id]/bookings": [46,[2,4]],
		"/(app)/(dashboard)/users/[id]/generate-invoice": [47,[2,4]],
		"/(app)/(dashboard)/users/[id]/invites": [49,[2,4]],
		"/(app)/(dashboard)/users/[id]/invite": [~48,[2,4]],
		"/(app)/(dashboard)/users/[id]/legacy/[...catchAll]": [~50,[2,4]],
		"/(app)/(dashboard)/users/[id]/membership": [51,[2,4]],
		"/(app)/(dashboard)/users/[id]/payment-methods": [52,[2,4]],
		"/(app)/(dashboard)/users/[id]/payments": [53,[2,4]],
		"/(app)/(dashboard)/users/[id]/photo": [54,[2,4]],
		"/(app)/(dashboard)/users/[id]/profile": [55,[2,4]],
		"/(app)/(dashboard)/users/[id]/reviews": [56,[2,4]],
		"/(app)/(dashboard)/users/[id]/user-strikes": [57,[2,4]],
		"/(app)/(dashboard)/[...catchAll]": [7,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';